import { getGridStringOperators, GridToolbarColumnsButton, GridToolbarDensitySelector, LicenseInfo, DataGridPro, getGridDateOperators } from "@mui/x-data-grid-pro";
import React, { useMemo } from 'react';
import { useContext, useEffect, useState } from "react";
import useStyles from "../common/CommonStyle/CommonStyle";
import GridTextLocalization from "../common/GridTextLocalization/GridTextLocalization";
import { SupportedLanguageDetails } from "../common/SupportedLanguageDetails/SupportedLanguageDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { CommonService } from "../../services";
import { Badge, Button, CssBaseline, Grid, Paper, Snackbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { Alert } from "@mui/lab";
import CustomBreadCrumb from "../common/CustomBreadCrumb";
import CommonGridPagination from "../common/CommonGridPagination/CommonGridPagination";
import RefreshIcon from '@mui/icons-material/Refresh';
import ChipInfoList from "../common/ChipInfoList";
import { CustomFilters } from "../common/CustomFilters/CustomFilters";
import { LoggedInUserDetails } from "../common/LoggedInUserDetails/LoggedInUserDetails";
import BookIcon from '@mui/icons-material/Book';
import { GlobalEdiApiConstants } from '../../Constants/GlobalEdiApiConstants';
import IconMessageConfiguration from '@mui/icons-material/AddComment';
import GetAppIcon from '@mui/icons-material/GetApp';
import CommonBackDrop from "../common/BackDrop";
import saveAs from 'file-saver';
import CustomFilterComponent, { GetFilterableColumns } from "../common/CustomFilterComponent";
import FilterListIcon from '@mui/icons-material/FilterList';
import { manageUserPreferences } from '../../services/manageUserPreferences';

const columnFieldsName = {
    statusMessage: "statusMsg",
    processMaster: "processMaster.process",
    commType: 'commType',
    msgType: 'msgType',
    emailTo: 'emailTo',
    emailCC: 'emailCC',
    tmplName: "tmplName",
    startTime: "startTime",
    endTime: "endTime",
    bpName: 'bpName',
    bpType: 'bpType',
    bpCode: 'bpCode',
    status: 'status',
    erpOrgCode: 'organization.erpOrgCode',
    erpEntityCode: 'entity.erpEntityCode',
    orgName: 'organization.orgName',
    entityName: 'entity.entityName',
}
function MessageTransmissionLog() {
    const classes = useStyles();
    const intl = useIntl();
    const theme = useTheme();
    const X_GRID_LICENSE_KEY = `${process.env.REACT_APP_X_GRID_LICENSE_KEY}`.toString();
    LicenseInfo.setLicenseKey(
        X_GRID_LICENSE_KEY,
    );
    const { loggedInUserInfo } = useContext(LoggedInUserDetails);
    const selectedLanguage = useContext(SupportedLanguageDetails);
    const localtext = GridTextLocalization();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

    const pageLabelsConstantsAndMessages = {
        label: {
            createButton: 'Create',
            editButton: 'Edit',
            deleteButton: 'Delete',
            refreshButton: 'Refresh',
            applyFilterButton: 'Apply Filter',
            columns: 'Columns to View',
            filters: 'Filter the columns',
            Density: 'Density'
        },
        alignment: {
            alignLeft: 'left',
            alignRight: 'right',
            alignCenter: 'center',
        },
    };

    const componentList = [
        {
            path: undefined,
            name: <FormattedMessage id="common.menu.breadcrumb.messageconfiguration" />,
            iconName: IconMessageConfiguration,
        },
        {
            path: '/messagetranssmissionlog',
            name: <FormattedMessage id="common.menu.breadcrumb.messagetransmissionlog" />,
            iconName: BookIcon,
        }
    ];

    const [alertBoxState, setAlertBoxState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const handleCloseAlert = () => {
        setAlertBoxState({ ...alertBoxState, openAlert: false });
    };
    const { vertical, horizontal, openAlert, message, severity } = alertBoxState;
    const [chipInfoList, setChipInfoList] = useState([]);
    const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();
    var defaultEntity = loggedInUserInfo.defaultEntity;
    const [paramBody, setParamBody] = useState({
        body: {
            entity: {
                erpEntityCode: defaultEntity,
                entityName: ''
            },
            organization: {
                erpOrgCode: '',
                orgName: ''
            },
            processMaster: {
                process: '',
                processId: ''
            },
            commType: '',
            bpCode: '',
            msgType: '',
            bpType: '',
            startTime: new Date().toISOString().slice(0, 10)
        },
        pageNo: 0,
        sortDir: 'desc',
        sortKey: 'msgTransmissionLogId',
        recordsPerPage: 25,
    }
    );

    const [tableLoadingStatus, setTableLoadingStatus] = useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [refreshTable, setRefreshTable] = useState(false);
    const [searchedOrgCode, setSearchedOrgCode] = useState('');
    const [searchedEntityCode, setSearchedEntityCode] = useState(defaultEntity);
    const [searchedBpType, setSearchedBpType] = useState('');
    const [searchedBpCode, setSearchedBpCode] = useState('');
    const [searchedBpName, setSearchedBpName] = useState('');
    const [searchedMsgType, setSearchedMsgType] = useState('');
    const [searchedCommType, setSearchedCommType] = useState('');
    const [searchedProcess, setSearchedProcess] = useState('');
    const [searchedStatus, setSearchedStatus] = useState('');
    const [searchedEntityName, setSearchedEntityName] = useState('');
    const [searchedStartTime, setSearchedStartTime] = useState('');
    const [searchedEndTime, setSearchedEndTime] = useState('');
    const [msgTransmissionLogPopUp, setMsgTransmissionLogPopUp] = useState(false);
    const [keyValueData, setKeyValueData] = useState({});
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [disiablingDownloadButtonForZeroRecords, setDisiablingDownloadButtonForZeroRecords] = useState(false)

    const [openFilterPanel, setFilterPanel] = useState(false);
    const handleClose = () => {
        setFilterPanel(false);
    }
    const [filterCount, setFilterCount] = React.useState(0);

    const defaulSelectedFilters = defaultEntity === '' ?
        [{ columnField: columnFieldsName.startTime, headerName: 'Start Time', id: 0, value: new Date().toISOString().slice(0, 10) }] :
        [{ columnField: columnFieldsName.startTime, headerName: 'Start Time', id: 0, value: new Date().toISOString().slice(0, 10) },
        { columnField: columnFieldsName.erpEntityCode, headerName: "Ent. Code", id: 1, operatorValue: "contains", value: defaultEntity }];

    const [selectedFilters, setSelectedFilters] = useState(defaulSelectedFilters);
    const [callSaveApiForOrderVisibility, setCallSaveApiForOrderVisibility] = useState(false);
    const [callSaveApiForSorting, setCallSaveApiForSorting] = useState(false);
    const [preference, setPreference] = useState(apiUrlAndParamBodyConstants.paramBodyForGetListApis.userPreference);
    const [pinnedColumns, setPinnedColumns] = useState(preference.columnsPinned);
    const [callSaveApiForPinning, setCallSaveApiForPinning] = useState(false);
    const [callSaveApiForFilter, setCallSaveApiForFilter] = useState(false);
    const [intialParamBodyApiCall, setIntialParamBodyApiCall] = useState(false);
    var componentName = 'common.menu.breadcrumb.messagetransmissionlog';

    function orderAndHideColumns(savedUserPreference, defaultUserPreference) {
        if (savedUserPreference.length !== 0 && defaultUserPreference.length !== 0) {
            var orderedUserPreferences = [],
                len = defaultUserPreference.length,
                len_copy = len,
                index, current;

            for (; len--;) {
                current = defaultUserPreference[len];
                index = savedUserPreference.findIndex(element => element.field === current.field);
                orderedUserPreferences[index] = current;
                orderedUserPreferences[index].hide = savedUserPreference[index].hide;
            }

            Array.prototype.splice.apply(defaultUserPreference, [0, len_copy].concat(orderedUserPreferences));
            return orderedUserPreferences;
        }
    }


    const savePreference = (preferenceChangeIn, params, orderedColumns) => {
        if (preferenceChangeIn === "filter") {
            setCallSaveApiForFilter(true);
            setPreference(prev => ({ ...prev, columnsFilter: params }));
        }
        else if (preferenceChangeIn === "sort") {
            setCallSaveApiForSorting(true);
            setPreference(prev => ({ ...prev, columnsSort: { field: params.field, sort: params.sort } }));
        }
        else if (preferenceChangeIn === "visibility" || preferenceChangeIn === "order") {
            var splicedFirstColumn = params;
            if (params[0].field === "__check__")
                splicedFirstColumn = params.splice(1, params.length);
            setCallSaveApiForOrderVisibility(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: splicedFirstColumn }));
        }
        else if (preferenceChangeIn === "pinned") {
            setCallSaveApiForPinning(true);
            setPreference(prev => ({ ...prev, columnsOrdervisible: orderedColumns, columnsPinned: params }));
        }
    }

    useEffect(() => {
        manageUserPreferences.saveLastOpenedScreen(componentName)
            .then((response) => { })
            .catch(function (error) {
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, []);

    useEffect(() => {
        if (callSaveApiForPinning === false)
            preference.columnsPinned = pinnedColumns;
        if (callSaveApiForFilter === false)
            preference.columnsFilter = selectedFilters;
        if (callSaveApiForOrderVisibility === false && callSaveApiForPinning === false)
            preference.columnsOrdervisible = columns;
        if (callSaveApiForSorting === false)
            preference.columnsSort = { field: paramBody.sortKey, sort: paramBody.sortDir };
        if ((callSaveApiForFilter === true || callSaveApiForPinning === true || callSaveApiForOrderVisibility === true || callSaveApiForSorting === true)
            && (preference.columnsOrdervisible !== undefined && preference.columnsOrdervisible !== null && preference.columnsOrdervisible?.length !== 0 && preference.columnsSort.field !== '')) {
            manageUserPreferences.saveGridPreferences({
                userId: 0,
                organization: {},
                entity: {},
                screenName: componentName,
                tabName: componentName,
                preferences: JSON.stringify(preference),
                lastOpen: true,
            }).then((response) => {
                if (callSaveApiForOrderVisibility === true) {
                    var defaultColumns = columns;
                    var userChangedColumns = preference.columnsOrdervisible;
                    var userPreferedColumns = orderAndHideColumns(userChangedColumns, defaultColumns);
                    setColumns(userPreferedColumns);
                    setCallSaveApiForOrderVisibility(false);
                }
                else if (callSaveApiForSorting === true) {
                    setCallSaveApiForSorting(false);
                }
                else if (callSaveApiForPinning === true) {
                    setPinnedColumns(preference.columnsPinned);
                    setColumns(preference.columnsOrdervisible);
                    setCallSaveApiForPinning(false);
                }
                else if (callSaveApiForFilter === true) {
                    setCallSaveApiForFilter(false);
                }
            })
                .catch(function (error) {
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [preference]);

    const [columns, setColumns] = useState([]);

    const columnsForMessageTransmissionLog =
        [
            {
                field: columnFieldsName.orgName,
                headerName: intl.formatMessage({ id: "columnname.OrganizationName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                valueGetter: (params) => `${params.row.organization.orgName}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.erpOrgCode,
                headerName: intl.formatMessage({ id: "columnname.organizationcode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: true,
                filterable: false,
                valueGetter: (params) => `${params.row.organization.erpOrgCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.entityName,
                headerName: intl.formatMessage({ id: "common.EntityName.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: false,
                valueGetter: (params) => `${params.row.entity.entityName}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.erpEntityCode,
                headerName: intl.formatMessage({ id: "columnname.entitycode.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 85,
                sortable: false,
                type: 'string',
                hide: false,
                filterable: true,
                valueGetter: (params) => `${params.row.entity.erpEntityCode}`,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.processMaster,
                width: 140,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                headerName: intl.formatMessage({ id: "columnname.process.label" }),
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                valueGetter: (params) => `${params.row.processMaster.process}`,
                renderHeader: CommonService.customRenderHeaderColumns,

            },
            {
                field: columnFieldsName.bpCode,
                headerName: intl.formatMessage({ id: "common.textfield.columname.bpcode" }),
                width: 150,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.bpType,
                headerName: intl.formatMessage({ id: "columnname.label.bptype" }),
                width: 150,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.bpName,
                headerName: intl.formatMessage({ id: "columnName.bpname.label" }),
                width: 300,
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                sortable: true,
                type: 'string',
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.commType,
                headerName: intl.formatMessage({ id: "columnname.commType.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 160,
                sortable: true,
                type: 'string',
                filterable: true,
                hide: false,
                renderHeader: CommonService.customRenderHeaderColumns,
            },
            {
                field: columnFieldsName.statusMessage,
                headerName: intl.formatMessage({ id: "columnname.statusMessage.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 190,
                sortable: true,
                filterable: false,
                type: 'string',
                hide: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.status,
                headerName: intl.formatMessage({ id: "columnname.status.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 140,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: CommonService.customRenderStatusColumnForTransmissionLog
            },
            {
                field: columnFieldsName.msgType,
                headerName: intl.formatMessage({ id: "columnname.messagetype.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 150,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns
            },
            {
                field: columnFieldsName.startTime,
                headerName: intl.formatMessage({ id: "columnname.startTime.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.startTimeDtDisp}</span>)
            },
            {
                field: columnFieldsName.endTime,
                headerName: intl.formatMessage({ id: "columnname.endTime.label" }),
                headerAlign: `${pageLabelsConstantsAndMessages.alignment.alignCenter}`,
                align: `${pageLabelsConstantsAndMessages.alignment.alignLeft}`,
                width: 180,
                sortable: true,
                type: 'string',
                hide: false,
                filterable: true,
                renderHeader: CommonService.customRenderHeaderColumns,
                renderCell: (cellParams) => (<span>{cellParams.row.endTimeDtDisp}</span>)
            },
        ]

    useEffect(() => {
        setIntialParamBodyApiCall(false);
        setTableLoadingStatus(true);
        manageUserPreferences.loadUserPreferences({
            body: {
                screenName: componentName,
                tabName: componentName,
                userId: 0,
                organization: {},
                entity: {}
            }

        }).then((response) => {
            setIntialParamBodyApiCall(true);
            if (response.data === null) {
                setColumns(columnsForMessageTransmissionLog);
                savePreference("visibility", columnsForMessageTransmissionLog);
                paramBody.body.entity.erpEntityCode = defaultEntity;
                paramBody.body.startTime = new Date().toISOString().slice(0, 10);
                setParamBody({ ...paramBody });
            }
            //preference present in db
            else {
                var savedColumnsInResponse = JSON.parse(response.data.preferences);
                var orderedColumns = orderAndHideColumns(savedColumnsInResponse.columnsOrdervisible, columnsForMessageTransmissionLog);
                setPinnedColumns(savedColumnsInResponse.columnsPinned);
                setColumns(orderedColumns);

                let orgName = null;
                let orgCode = null;
                let entityCode = null;
                let entityName = null;
                let bpName = null;
                let bpType = null;
                let bpCode = null;
                let status = null;
                let processMaster = null;
                let msgType = null;
                let commType = null;
                let startTime = null;
                let endTime = null;
                savedColumnsInResponse.columnsFilter.map((columns) => {
                    switch (columns.columnField) {
                        case 'bpName':
                            bpName = columns.value
                            break;
                        case 'organization.orgName':
                            orgName = columns.value
                            break;
                        case 'organization.erpOrgCode':
                            orgCode = columns.value
                            break;
                        case 'entity.erpEntityCode':
                            entityCode = columns.value
                            break;
                        case 'entity.entityName':
                            entityName = columns.value
                            break;
                        case 'bpCode':
                            bpCode = columns.value
                            break;
                        case 'bpType':
                            bpType = columns.value
                            break;
                        case 'status':
                            status = columns.value
                            break;
                        case 'processMaster.process':
                            processMaster = columns.value
                            break;
                        case 'msgType':
                            msgType = columns.value
                            break;
                        case 'commType':
                            commType = columns.value
                            break;
                        case 'startTime':
                            startTime = columns.value
                            break;
                        case 'endTime':
                            endTime = columns.value
                            break;
                    }
                }
                )


                setSearchedBpType(bpType);
                setSearchedBpCode(bpCode);
                setSearchedOrgCode(orgCode);
                setSearchedEntityCode(entityCode);
                setSearchedCommType(commType);
                setSearchedMsgType(msgType);
                setSearchedStatus(status);
                setSearchedEntityName(entityName);
                setSearchedProcess(processMaster);
                setSearchedStartTime(startTime);
                setSearchedEndTime(endTime);
                setSearchedBpName(bpName);

                var filterBody = {
                    status: status === 'Success' ? 'SUCCESS' : status === 'Failed' ? 'FAILED' : status === 'Partial' ? 'PARTIAL' : status === 'Pending' ? 'PENDING' : '',
                    organization: {
                        erpOrgCode: orgCode, orgName: orgName
                    },
                    entity: {
                        erpEntityCode: entityCode,
                        entityName: entityName
                    },
                    bpType: bpType,
                    bpCode: bpCode,
                    bpName: bpName,
                    msgType: msgType,
                    commType: commType,
                    processMaster: processMaster === 'Advance Shipment Note' ? 3 : processMaster === 'Customer' ? 13 :
                        processMaster === 'Inventory Report' ? 6 : processMaster === 'Invoice' ? 5 :
                            processMaster === 'PO Delivery Confirmation' ? 14 : processMaster === 'PO PDF' ? 8 :
                                processMaster === 'Purchase Forecast' ? 2 : processMaster === 'Purchase Order' ? 11 :
                                    processMaster === 'Purchase Order/Purchase Forecast' ? 1 : processMaster === 'Supplier' ? 12 : processMaster === 'VMI Consumption Report' ? 7 : '',
                    startTime: startTime,
                    endTime: endTime,
                };
                paramBody.body = filterBody;
                //default entity filter on no user preferences  filters in db
                if (savedColumnsInResponse.columnsFilter.length <= 0) {
                    paramBody.body.entity.erpEntityCode = defaultEntity;
                    paramBody.body.startTime = new Date().toISOString().slice(0, 10);
                }
                paramBody.sortDir = savedColumnsInResponse.columnsSort.sort;
                paramBody.sortKey = savedColumnsInResponse.columnsSort.field;
                setParamBody({ ...paramBody });

                if (savedColumnsInResponse.columnsFilter.length > 0)
                    setSelectedFilters(savedColumnsInResponse.columnsFilter);
            }
        })
            .catch(function (error) {
                setTableLoadingStatus(false);
                setIntialParamBodyApiCall(true);
                if (error.response !== undefined) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                }
            });

    }, [selectedLanguage])


    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (totalRowCount === 0) {
            setDisiablingDownloadButtonForZeroRecords(true)
        } else {
            setDisiablingDownloadButtonForZeroRecords(false)
        }
    }, [totalRowCount])

    useEffect(() => {
        if (intialParamBodyApiCall) {
            setTableLoadingStatus(true);
            setTableData([]);
            CommonService.GetListApi(apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.messageTransmissionLog, paramBody)
                .then((response) => {
                    setTableLoadingStatus(false);
                    setTotalRowCount(response.data.totalElements);
                    setTableData(response.data.content);
                    (response.data.totalElements > 0 ?
                        setChipInfoList(response.data.content[0].headerChipList === null ? [] : response.data.content[0].headerChipList)
                        :
                        setChipInfoList([]));
                })
                .catch(function (error) {
                    setTableLoadingStatus(false);
                    if (error.response !== undefined) {
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response.data.message, severity: "error" });
                    }
                });
        }
    }, [paramBody, refreshTable])


    if (columns.length > 0) {
        const bpTypeColumn = columns.find((column) => column.field === columnFieldsName.bpType);
        const bpTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpType);

        const bpTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.BpTypeInputValue,
            }),
        );

        columns[bpTypeColIndex] = {
            ...bpTypeColumn,
            filterOperators: bpTypeFilterOperators,
        };

        const orgCodeColumn = columns.find((column) => column.field === columnFieldsName.erpOrgCode);
        const orgCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpOrgCode);

        const orgCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgCodeInputValue,
            }),
        );

        columns[orgCodeColIndex] = {
            ...orgCodeColumn,
            filterOperators: orgCodeFilterOperators,
        };

        const entityCodeColumn = columns.find((column) => column.field === columnFieldsName.erpEntityCode);
        const entityCodeColIndex = columns.findIndex((col) => col.field === columnFieldsName.erpEntityCode);

        const entityCodeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityCodeInputValue,
            }),
        );

        columns[entityCodeColIndex] = {
            ...entityCodeColumn,
            filterOperators: entityCodeFilterOperators,
        };

        const processColumn = columns.find((column) => column.field === columnFieldsName.processMaster);
        const processColIndex = columns.findIndex((col) => col.field === columnFieldsName.processMaster);

        const processFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.ProcessInputValue,
            }),
        );

        columns[processColIndex] = {
            ...processColumn,
            filterOperators: processFilterOperators,
        };

        const msgTypeColumn = columns.find((column) => column.field === columnFieldsName.msgType);
        const msgTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.msgType);

        const msgTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.MsgTypeLogInputValue,
            }),
        );

        columns[msgTypeColIndex] = {
            ...msgTypeColumn,
            filterOperators: msgTypeFilterOperators,
        };

        const commTypeColumn = columns.find((column) => column.field === columnFieldsName.commType);
        const commTypeColIndex = columns.findIndex((col) => col.field === columnFieldsName.commType);

        const commTypeFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.CommTypeLogInputValue,
            }),
        );

        columns[commTypeColIndex] = {
            ...commTypeColumn,
            filterOperators: commTypeFilterOperators,
        };

        const statusColumn = columns.find((column) => column.field === columnFieldsName.status);
        const statusColIndex = columns.findIndex((col) => col.field === columnFieldsName.status);

        const statusFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.SuccessFailureStatusColInputValue,
            }),
        );

        columns[statusColIndex] = {
            ...statusColumn,
            filterOperators: statusFilterOperators,
        };

        const orgNameColumn = columns.find((column) => column.field === columnFieldsName.orgName);
        const orgNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.orgName);

        const orgNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.OrgNameInputValue,
            }),
        );

        columns[orgNameColIndex] = {
            ...orgNameColumn,
            filterOperators: orgNameFilterOperators,
        };

        const entityNameColumn = columns.find((column) => column.field === columnFieldsName.entityName);
        const entityNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.entityName);

        const entityNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.EntityNameInputValue,
            }),
        );

        columns[entityNameColIndex] = {
            ...entityNameColumn,
            filterOperators: entityNameFilterOperators,
        };

        const startTimeColumn = columns.find((column) => column.field === columnFieldsName.startTime);
        const startTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.startTime);

        const startTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[startTimeColIndex] = {
            ...startTimeColumn,
            filterOperators: startTimeFilterOperators,
        };

        const endTimeColumn = columns.find((column) => column.field === columnFieldsName.endTime);
        const endTimeColIndex = columns.findIndex((col) => col.field === columnFieldsName.endTime);

        const endTimeFilterOperators = getGridDateOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.DateInputValue,
            }),
        );

        columns[endTimeColIndex] = {
            ...endTimeColumn,
            filterOperators: endTimeFilterOperators,
        };

        const bpNameColumn = columns.find((column) => column.field === columnFieldsName.bpName);
        const bpNameColIndex = columns.findIndex((col) => col.field === columnFieldsName.bpName);

        const bpNameFilterOperators = getGridStringOperators().map(
            (operator) => ({
                ...operator,
                InputComponent: CustomFilters.BPMasterInputValue,
            }),
        );

        columns[bpNameColIndex] = {
            ...bpNameColumn,
            filterOperators: bpNameFilterOperators,
        };
    }

    const filterableColumns = useMemo(() => GetFilterableColumns(columns), [columns])

    const key = "columnField"

    const uniqueSelectedFiltersData = [...new Map(selectedFilters.map(item => [item[key], item])).values()];

    const applyFilter = () => {
        let orgName = null;
        let orgCode = null;
        let entityCode = null;
        let entityName = null;
        let bpName = null;
        let bpType = null;
        let bpCode = null;
        let status = null;
        let processMaster = null;
        let msgType = null;
        let commType = null;
        let startTime = null;
        let endTime = null;
        selectedFilters.map((columns) => {
            switch (columns.columnField) {
                case 'bpName':
                    bpName = columns.value
                    break;
                case 'organization.orgName':
                    orgName = columns.value
                    break;
                case 'organization.erpOrgCode':
                    orgCode = columns.value
                    break;
                case 'entity.erpEntityCode':
                    entityCode = columns.value
                    break;
                case 'entity.entityName':
                    entityName = columns.value
                    break;
                case 'bpCode':
                    bpCode = columns.value
                    break;
                case 'bpType':
                    bpType = columns.value
                    break;
                case 'status':
                    status = columns.value
                    break;
                case 'processMaster.process':
                    processMaster = columns.value
                    break;
                case 'msgType':
                    msgType = columns.value
                    break;
                case 'commType':
                    commType = columns.value
                    break;
                case 'startTime':
                    startTime = columns.value
                    break;
                case 'endTime':
                    endTime = columns.value
                    break;
            }
        }
        )

        setSearchedBpType(bpType);
        setSearchedBpCode(bpCode);
        setSearchedOrgCode(orgCode);
        setSearchedEntityCode(entityCode);
        setSearchedCommType(commType);
        setSearchedMsgType(msgType);
        setSearchedStatus(status);
        setSearchedEntityName(entityName);
        setSearchedProcess(processMaster);
        setSearchedStartTime(startTime);
        setSearchedEndTime(endTime);
        setSearchedBpName(bpName);

        savePreference("filter", selectedFilters.length === 0 ? defaulSelectedFilters : selectedFilters);
        setParamBody(prevState => {
            return {
                ...prevState, body: {
                    status: status === 'Success' ? 'SUCCESS' : status === 'Failed' ? 'FAILED' : status === 'Partial' ? 'PARTIAL' : status === 'Pending' ? 'PENDING' : '',
                    organization: {
                        erpOrgCode: orgCode, orgName: orgName
                    },
                    entity: {
                        erpEntityCode: entityCode,
                        entityName: entityName
                    },
                    bpType: bpType,
                    bpCode: bpCode,
                    bpName: bpName,
                    msgType: msgType,
                    commType: commType,
                    processMaster: processMaster === 'Advance Shipment Note' ? 3 : processMaster === 'Customer' ? 13 :
                        processMaster === 'Inventory Report' ? 6 : processMaster === 'Invoice' ? 5 :
                            processMaster === 'PO Delivery Confirmation' ? 14 : processMaster === 'PO PDF' ? 8 :
                                processMaster === 'Purchase Forecast' ? 2 : processMaster === 'Purchase Order' ? 11 :
                                    processMaster === 'Purchase Order/Purchase Forecast' ? 1 : processMaster === 'Supplier' ? 12 : processMaster === 'VMI Consumption Report' ? 7 : '',
                    startTime: startTime,
                    endTime: endTime,
                }
            }
        })
    }

    let filterBodyObj = {
        bpType: searchedBpType === '' ? null : searchedBpType,
        bpCode: searchedBpCode === '' ? null : searchedBpCode,
        erpOrgCode: searchedOrgCode === '' ? null : searchedOrgCode,
        erpEntityCode: searchedEntityCode === null ? "" : searchedEntityCode,
        msgType: searchedMsgType === '' ? null : searchedMsgType,
        commType: searchedCommType === '' ? null : searchedCommType,
        status: searchedStatus === 'Success' ? 'SUCCESS' : searchedStatus === 'Failed' ? 'FAILED' : searchedStatus === 'Partial' ? 'PARTIAL' : searchedStatus === 'Pending' ? 'PENDING' : null,
        process: searchedProcess === '' ? null : searchedProcess,
        startTime: searchedStartTime === '' ? null : searchedStartTime,
        endTime: searchedEndTime === '' ? null : searchedEndTime,
        bpName: searchedBpName === '' ? null : searchedBpName
    }

    useEffect(() => {
        let colValDataObj = {}
        for (let key in filterBodyObj) {
            let value = filterBodyObj[key]
            if (filterBodyObj.hasOwnProperty(key) && ((value !== null) && (value !== 0) && (value !== undefined) && (value !== {}))) {
                colValDataObj[key] = value.toString();
            }
            setKeyValueData(colValDataObj)
        }
    }, [searchedStartTime, searchedEndTime, searchedBpType, searchedBpCode, searchedOrgCode, searchedEntityCode, searchedMsgType, searchedCommType, searchedStatus, searchedProcess, searchedBpName]);

    useEffect(() => {
        if (msgTransmissionLogPopUp === true) {
            setOpenBackDrop(true);
            CommonService.saveOrUpdateOrPost('/reports/SYS_MSG_TRANSMISSION_lOG', searchedEntityCode === '' && searchedStartTime === '' ?
                { erpEntityCode: '', startTime: new Date().toISOString().slice(0, 10) } : searchedStartTime === '' ? { erpEntityCode: defaultEntity, startTime: new Date().toISOString().slice(0, 10) } : keyValueData)
                .then((response) => {
                    if (response.data.sucess === "No records found for given condition")
                        setAlertBoxState({ ...alertBoxState, openAlert: true, message: response.data.sucess, severity: "error" });
                    else {
                        //showDownloadPopUpExcelMsgLog(response.data.fileUrl)
                        setAlertBoxState({
                            ...alertBoxState, openAlert: true, message: <FormattedMessage id="yourrequestno.snackbar.text" values={{ responsedata: `${response.data.value}`, reportName: `${response.data.reportName}` }} />, severity: "success"
                        });
                    }
                    setOpenBackDrop(false);
                })
                .catch(function (error) {
                    setAlertBoxState({ ...alertBoxState, openAlert: true, message: error.response === undefined ? 'Error occured' : error.response.message, severity: "error" });
                    setOpenBackDrop(false);
                });
            setMsgTransmissionLogPopUp(false)
        }
    }, [msgTransmissionLogPopUp, keyValueData]);

    const showDownloadPopUpExcelMsgLog = (filePath) => {
        const filePathReplaceWithDoubleBackwardSlash = filePath.replaceAll('\\', '/');
        const fileName = filePathReplaceWithDoubleBackwardSlash.split('/')[filePathReplaceWithDoubleBackwardSlash.split('/').length - 1].trim();
        fetch(`${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePathReplaceWithDoubleBackwardSlash}`, {
            method: 'GET',
            headers: {
                authorization: 'Bearer ' + CommonService.getJwtTokenOfLoggedInUser()
            },
        })
            .then((response) => {
                return response.blob();
            }
            )
            .then((blob) => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="successmessage.dowload" defaultMessage="File downloaded successfully" />, severity: "success" });
                saveAs(blob, fileName);
            })
            .catch(error => {
                setAlertBoxState({ ...alertBoxState, openAlert: true, message: <FormattedMessage id="errorMessage.download" defaultMessage="Failed to download the file!" />, severity: "error" });
            })
    }

    useEffect(() => {
        if (intialParamBodyApiCall)
            setFilterCount([...new Map(selectedFilters.map(item => [item[key], item])).values()].length)
    }, [selectedFilters, tableData])

    return (
        <React.Fragment>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseAlert} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <CommonBackDrop open={openBackDrop} />
            <Grid container className={classes.rootForRole}>
                <Grid item container justifyContent='flex-start' alignItems='center' xs={12} sm={12} md={12} lg={12} className={classes.topGrid}>
                    <CustomBreadCrumb componentList={componentList} />
                </Grid>
                <ChipInfoList chipInfoList={chipInfoList} />
                <CustomFilterComponent open={openFilterPanel} onClose={handleClose} filterableColumns={filterableColumns}
                    setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} applyFilter={applyFilter}
                    defaulSelectedFilters={defaulSelectedFilters} />
                <Paper elevation={3} className={classes.gridHeightAndWidthForRole}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGridPro
                            className={classes.customTableStyle}
                            sortingMode="server"
                            rows={tableData}
                            columns={columns}
                            pageSize={paramBody.recordsPerPage}
                            loading={tableLoadingStatus}
                            rowCount={totalRowCount}
                            scrollbarSize={30}
                            disableColumnFilter={true}
                            rowsPerPageOptions={[1, 2, 10, 25, 50, 100]}
                            pagination
                            density="compact"
                            paginationMode="server"
                            disableSelectionOnClick={true}
                            disableColumnMenu={false}
                            disableMultipleColumnsSorting={true}
                            hideFooter={true}
                            localeText={localtext}
                            onPageSizeChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, recordsPerPage: pageParams } })
                            })}
                            onPageChange={((pageParams) => {
                                setParamBody(prevState => { return { ...prevState, pageNo: (pageParams) } })
                            })}
                            pinnedColumns={pinnedColumns}
                            onPinnedColumnsChange={((params) => {
                                var OrderedColumns = columns;
                                if (pinnedColumns.left.length > params.left.length && pinnedColumns.right.length < params.right.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length && pinnedColumns.left.length < params.left.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length < params.left.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.left[params.left.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(pinnedColumns.left.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length < params.right.length) {
                                    var index = OrderedColumns.findIndex(element => element.field === params.right[params.right.length - 1]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(OrderedColumns.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.right.length > params.right.length) {
                                    var unpinnedColumns = pinnedColumns.right.filter(x => params.right.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(-params.right.length, 0, reOrderedColumns[0]);
                                }
                                else if (pinnedColumns.left.length > params.left.length) {
                                    var unpinnedColumns = pinnedColumns.left.filter(x => params.left.indexOf(x) === -1);
                                    var index = OrderedColumns.findIndex(element => element.field === unpinnedColumns[0]);
                                    var reOrderedColumns = OrderedColumns.splice(index, 1);
                                    OrderedColumns.splice(params.left.length, 0, reOrderedColumns[0]);
                                }
                                savePreference("pinned", {
                                    left: params.left,
                                    right: params.right
                                }, OrderedColumns);

                            })}
                            onColumnVisibilityChange={((params) => {
                                if (params.field !== '__check__') {
                                    var visibleColumns = columns;
                                    var index = visibleColumns.findIndex(element => element.field === params.field);
                                    visibleColumns[index].hide = !params.isVisible;
                                    savePreference("visibility", visibleColumns);
                                }
                            })}
                            onColumnOrderChange={((params) => {
                                var OrderedColumns = columns;
                                var reOrderedColumns = OrderedColumns.splice(params.oldIndex, 1);
                                OrderedColumns.splice(params.targetIndex, 0, reOrderedColumns[0]);
                                savePreference("order", OrderedColumns);
                            })}
                            onSortModelChange={(params) => {

                                let sortModel = params[0];
                                var previousParamBody = paramBody;
                                if (sortModel !== undefined && !(previousParamBody.sortDir === sortModel.sort && previousParamBody.sortKey === sortModel.field)) {

                                    setParamBody({ ...paramBody, sortDir: sortModel.sort, sortKey: sortModel.field });
                                    savePreference("sort", params[0]);
                                } else if (sortModel === undefined && !(previousParamBody.sortDir === null && previousParamBody.sortKey === null)) {
                                    setParamBody({ ...paramBody, sortDir: null, sortKey: null });
                                    savePreference("sort", { field: null, sort: null });
                                }
                            }}
                            sortModel={[{
                                field: paramBody.sortKey,
                                sort: paramBody.sortDir,
                            }]}
                            sortingOrder={['desc', 'asc']}
                            components={{
                                Toolbar: () => {
                                    return (
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" style={mobileScreen ? { marginBottom: '-0.1%', marginTop: '0%' } : { marginBottom: '-0.3%', marginTop: '-0.5%' }}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.columntoolbar.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.columns} />} placement='bottom'>
                                                        <GridToolbarColumnsButton className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.density.tooltip" defaultMessage={pageLabelsConstantsAndMessages.label.Density} />}
                                                        placement='bottom'>
                                                        <GridToolbarDensitySelector className={classes.toobarStyle} variant="outlined" size="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.toolbarfilterstooltipshow.toolbar.lable" />} placement='bottom'>
                                                        <span>
                                                            <Button className={classes.refreshToobarStyle} variant="outlined" size="medium" onClick={() => setFilterPanel(true)}>
                                                                <Badge color="primary" badgeContent={filterCount}>
                                                                    <FilterListIcon />
                                                                </Badge>
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.button.refresh.tooltip" defaultMessage="Refresh" />} placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={(() => {
                                                                    setRefreshTable(!refreshTable);
                                                                })}>
                                                                <RefreshIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid>
                                                    <Tooltip title={<FormattedMessage id="common.tooltip.breadcrumb.downloadExcelForMsg" />}
                                                        placement='bottom'>
                                                        <span>
                                                            <Button size="small" color="primary" variant="outlined"
                                                                className={classes.refreshToobarStyle}
                                                                onClick={() => {
                                                                    setMsgTransmissionLogPopUp(true)
                                                                }}
                                                                disabled={disiablingDownloadButtonForZeroRecords}
                                                            >
                                                                <GetAppIcon size={20} />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <CommonGridPagination />
                                            </Grid>
                                        </Grid>
                                    );
                                },
                            }}
                            sortingOrder={['desc', 'asc']}
                            filterMode="server"
                        />
                    </div>
                </Paper>

            </Grid>
        </React.Fragment>
    )
}

export default MessageTransmissionLog;
