import {
  CircularProgress,
  CssBaseline,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@mui/lab";
import { CommonService } from "../../../../services";
import PropTypes from "prop-types";
import _ from "lodash/fp";
import useStyles from "../../CommonStyle/CommonStyle";
import { FormattedMessage } from "react-intl";
import { GlobalEdiApiConstants } from "../../../../Constants/GlobalEdiApiConstants";

const EntityCodeCombo = props => {
  const classes = useStyles();
  const { comboProperty, control, errors, comboValue, setComboValue } = props;
  const [entityList, setEntityList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const entityData = entityList.map(entity => {
    return {
      erpEntityCode: entity.erpEntityCode,
      entityName: entity.entityName,
      timeZone: entity.timeZone,
      valuesToBeDisplayed: `${entity.erpEntityCode} -  ${entity.entityName}`,
      earlierAcceptanceDays: entity.earlierAcceptanceDays,
      lateAcceptanceDays: entity.lateAcceptanceDays
    };
  });

  const apiUrlAndParamBodyConstants = GlobalEdiApiConstants();

  const [searchedEntityCode, setSearchedEntityCode] = useState(null);

  const [paramBody, setParamBody] = useState(
    apiUrlAndParamBodyConstants.paramBodyForGetListApis.entity
  );

  useEffect(() => {
    if (searchedEntityCode !== null) {
      setParamBody({
        ...paramBody,
        body: { erpEntityCode: searchedEntityCode, recordActiveStatus: "All" },
        sortDir: "asc",
        sortKey: "erpEntityCode",
        recordsPerPage: 100
      });
    }
  }, [searchedEntityCode]);

  useEffect(() => {
    if (paramBody.sortDir !== "desc" && paramBody.sortKey !== "updatedTime") {
      loadEntityData();
    }
  }, [paramBody]);

  const loadEntityData = searchedEntityCode => {
    setLoading(true);
    CommonService.GetListApi(
      apiUrlAndParamBodyConstants.commonApiUrlForAllTheScreen.entity,
      paramBody
    ).then(response => {
      setLoading(false);
      setEntityList(response.data.content);
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {comboProperty.isHookFormType && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);

                    if (typeof newValue === "string") {
                      if (newValue != null) {
                        setComboValue({
                          newValue
                        });
                      }
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setComboValue({
                        title: newValue.inputValue
                      });
                    } else if (newValue !== null) {
                      setComboValue(newValue);
                    } else if (newValue === null) {
                      setComboValue("");
                    } else {
                      setComboValue(newValue);
                    }
                  }}
                  selectOnFocus
                  handleHomeEndKeys
                  value={comboValue.valuesToBeDisplayed || null}
                  autoHighlight
                  loadingText="Loading..."
                  loading={isLoading}
                  onOpen={() => {
                    setSearchedEntityCode("");
                  }}
                  options={entityData}
                  getOptionLabel={option => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return `${option.erpEntityCode} - ${option.entityName}`;
                  }}
                  getoptionselected={(option, value) =>
                    `${option.erpEntityCode} - ${option.entityName}` === value
                  }
                  disabled={comboProperty.isDisabled}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required={
                        comboProperty.isRequired === true ? true : false
                      }
                      autoFocus={comboProperty.isAutofocus}
                      name={comboProperty.name}
                      error={comboProperty.errors}
                      size="small"
                      label={comboProperty.label}
                      variant={comboProperty.variant}
                      InputLabelProps={{
                        shrink: true,
                        classes: {
                          asterisk: classes.asterisk
                        }
                      }}
                    />
                  )}
                />
              )}
              name={comboProperty.name}
              rules={{ required: comboProperty.isRequired }}
              control={control}
              defaultValue={{ erpEntityCode: "", erpOrgCode: "" }}
            />
            {comboProperty.isDisabled}
            <div className={classes.error}>
              {_.get(`${comboProperty.name}.type`, errors) === "required" && (
                <FormHelperText className={classes.error}>
                  {comboProperty.label}{" "}
                  <FormattedMessage
                    id="textfield.error.isrequirededmessage"
                    defaultMessage="is required"
                  />
                </FormHelperText>
              )}
            </div>
          </FormControl>
        </Grid>
      )}

      {!comboProperty.isHookFormType && (
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "3px" }}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              openOnFocus={true}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  if (newValue != null) {
                    setComboValue({
                      newValue
                    });
                  }
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setComboValue({
                    title: newValue.inputValue
                  });
                } else if (newValue !== null) {
                  setComboValue(newValue);
                } else if (newValue === null) {
                  setComboValue("");
                } else {
                  setComboValue(newValue);
                }
              }}
              selectOnFocus
              loadingText="Loading..."
              loading={isLoading}
              handleHomeEndKeys
              value={comboValue.erpEntityCode || null}
              autoHighlight
              onOpen={() => {
                setSearchedEntityCode("");
              }}
              options={entityData}
              getOptionLabel={option => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.erpEntityCode;
              }}
              getoptionselected={(option, value) =>
                option.erpEntityCode === value
              }
              disabled={comboProperty.isDisabled}
              renderInput={params => (
                <Tooltip title={comboValue.erpEntityCode || ""}>
                  <TextField
                    {...params}
                    autoFocus={comboProperty.isAutofocus}
                    name={comboProperty.name}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={ev => {
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        setSearchedEntityCode(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                    size="small"
                    label={
                      comboProperty.label +
                      (comboProperty.isRequired ? " *" : "")
                    }
                    variant={comboProperty.variant}
                  />
                </Tooltip>
              )}
            />
          </FormControl>
        </Grid>
      )}
    </React.Fragment>
  );
};

EntityCodeCombo.propTypes = {
  comboProperty: PropTypes.object.isRequired,
  control: PropTypes.any,
  errors: PropTypes.any,
  comboValue: PropTypes.any.isRequired,
  setComboValue: PropTypes.func.isRequired
};

export default EntityCodeCombo;
